export const formatCurrency = (value: number) => {
  return value
    ? value
        .toLocaleString("vi-VN", { style: "currency", currency: "VND" })
        .replace("VND", "đ")
    : "0 đ";
};

export const discountPrice = (price: number, discount: number) => {
  return price - (price * discount) / 100;
};

export const getBankTransferInfomation = (depositCode: string) => [
  {
    highlight: false,
    title: "Số Tài Khoản",
    value: "4235887",
  },
  {
    highlight: false,
    title: "Tên Tài Khoản",
    value: "NGUYEN HUY LAN",
  },
  {
    highlight: false,
    title: "Ngân Hàng",
    value: "ACB",
  },
  {
    highlight: true,
    title: "Nội dung chuyển tiền",
    value: depositCode,
  },
];

export const downloadBase64Image = (base64Data: string, fileName: string) => {
  if (!base64Data) {
    console.error("Base64 data is undefined or empty");
    return;
  }

  const isBase64Image = base64Data.startsWith("data:image/");
  if (!isBase64Image) {
    console.error(
      'Invalid base64 image data. Ensure it starts with "data:image/". Given data:',
      base64Data
    );
    return;
  }

  try {
    const base64Parts = base64Data.split(",");
    if (base64Parts.length !== 2) {
      console.error(
        'Base64 string is not in the correct format. Expected format: "data:image/<type>;base64,<data>"'
      );
      return;
    }

    const mimeType = base64Parts[0].match(/:(.*?);/)?.[1];
    const binaryData = atob(base64Parts[1]);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: mimeType });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(link.href);
    console.log("Download initiated successfully");
  } catch (error) {
    console.error("Error occurred while processing the Base64 data:", error);
  }
};
